<template>
  <div class="workbook">
    <workbook-header />
    <workbook-content />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import WorkbookHeader from './WorkbookHeader.vue'
import WorkbookContent from './WorkbookContent.vue'
export default {
  components: { WorkbookHeader, WorkbookContent },
  name: 'Workbook',
  created() {
    this.fetchWorkbook(this.$route.params.id)
  },
  methods: {
    ...mapActions('workbook', ['fetchWorkbook']),
  },
}
</script>

<style lang="scss"></style>
