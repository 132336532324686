<template>
  <div>
    <div class="quiz__content workbook" v-if="current && !isWorkbookCompleted">
      <div>
        <div class="quiz__title">{{ current.name }}</div>
        <p class="quiz__description pb20">
          {{ current.description }}
        </p>
        <p v-if="isGoals" class="quiz__sub-name">{{ current.sub_name }}</p>
      </div>
      <div class="quiz__fields">
        <template v-if="workbookFieldType.MULTI_SELECT === current.type">
          <div
            class="quiz__field-wrapper"
            v-for="g in current.values"
            :key="g.key"
          >
            <input
              type="checkbox"
              @change="onCheckboxChange($event, current.id)"
              :name="current.id"
              :id="g.key"
              :checked="form[current.id] && form[current.id].includes(g.key)"
              class="quiz__checkbox"
              :value="g.key"
            />
            <label class="quiz__label-wrapper" :for="g.key">
              <!-- <div class="quiz__field-icon" v-html="goalIcon[g.text]"></div> -->
              <div class="quiz__field-text">{{ g.value }}</div>
            </label>
          </div>
        </template>
        <template v-if="workbookFieldType.TEXT === current.type">
          <div class="quiz__field-wrapper">
            <textarea
              class="quiz__textarea"
              v-model="form[current.id]"
              :placeholder="current.placeholder"
            />
          </div>
        </template>
        <template v-if="workbookFieldType.MULTI_TEXT === current.type">
          <div class="quiz__field-wrapper">
            <textarea
              v-for="(t, index) in form[current.id]"
              :key="index"
              class="quiz__textarea small"
              @input="onChangeTextarea($event, current.id, index)"
              :value="(form[current.id] && form[current.id][index]) || ''"
              :placeholder="current.placeholder"
            />
            <div class="flex-center add-btn">
              <button @click="onAddTextarea" class="btn-reset">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 0a18 18 0 100 36 18 18 0 000-36zm9.391 18.783h-8.608v8.608a.782.782 0 01-1.566 0v-8.608H8.61a.783.783 0 010-1.566h8.608V8.61a.782.782 0 111.566 0v8.608h8.608a.783.783 0 010 1.566z" fill="#fff"/></svg>
              </button>
            </div>
          </div>
        </template>
        <template v-if="workbookFieldType.SELECT === current.type">
          <div class="quiz__fields">
            <div
              class="quiz__field-wrapper"
              v-for="e in current.values"
              :key="e.key"
            >
              <input
                v-model="form[current.id]"
                type="radio"
                name="e.key"
                :id="e.key"
                class="quiz__checkbox"
                :value="e.key"
              />
              <label class="quiz__label-wrapper" :for="e.key">
                <div class="quiz__field-text">
                  {{ e.value }}
                </div>
              </label>
            </div>
          </div>
        </template>
        <template v-if="workbookFieldType.RANGE === current.type">
          <div class="quiz__progress no-margin">
            <div class="value-slider">{{ form[current.id] }}</div>
            <a-slider
              class="slider"
              :value="form[current.id]"
              @change="(e) => (form[current.id] = e)"
              :tooltipVisible="false"
            />
            <div class="text-slider">
              <span>Low anxiety</span>
              <span>High anxiety</span>
            </div>
          </div>
        </template>
      </div>
      <button
        @click="onContinue"
        :disabled="
          (!form[current.id] && !current.noValue) ||
          (Array.isArray(form[current.id]) &&
            (!form[current.id].length || !form[current.id].some((i) => i)))
        "
        class="quiz__btn btn-reset quiz-btn quiz-btn--md quiz-btn--white"
      >
        Continue
      </button>
    </div>
    <div
      class="quiz__content workbook"
      v-if="isWorkbookCompleted && !isLoading"
    >
      <div>
        <div class="quiz__title">Great Work.</div>
        <p class="quiz__description quiz__description--md">
          You have successfully completed this workbook. You can review and
          reflect on it in your Profile.
        </p>
        <div class="flex-center">
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M56.6663 28.333L31.6663 51.6663L23.333 43.333"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M40.0003 78.3337C61.1713 78.3337 78.3337 61.1713 78.3337 40.0003C78.3337 18.8294 61.1713 1.66699 40.0003 1.66699C18.8294 1.66699 1.66699 18.8294 1.66699 40.0003C1.66699 61.1713 18.8294 78.3337 40.0003 78.3337Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <button
        @click="onDone"
        class="quiz__btn btn-reset quiz-btn quiz-btn--md quiz-btn--white"
      >
        Done
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { workbookFieldType, workbookType } from './workbook-data'
export default {
  name: 'WorkbookContent',
  data() {
    return {
      form: {},
      workbookFieldType,
      workbookType,
    }
  },
  computed: {
    ...mapState('workbook', [
      'workbook',
      'currentIndex',
      'isLoading',
      'generalIssuesForm',
      'questions',
      'answerForm',
    ]),
    isExposureHierarchy() {
      return this.workbookType.EXPOSURE_HIERARCHY === this.workbook.name
    },
    isGoals() {
      return this.workbookType.GOALS === this.workbook.name
    },
    isComponentsEmotion() {
      return this.workbookType.COMPONENTS_EMOTION === this.workbook.name
    },
    current() {
      const q = this.questions[this.currentIndex]
      if (q && q.type === this.workbookFieldType.RANGE && !this.form[q.id]) {
        this.form[q.id] = 50
      }
      if (
        q &&
        q.type === this.workbookFieldType.MULTI_TEXT &&
        !Array.isArray(this.form[q.id])
      ) {
        this.form[q.id] = ['']
      }
      if (q && q.values) {
        q.values = q.values.map((i) => {
          if (typeof i !== 'object') {
            return {
              key: i,
              value: i,
            }
          }
          if (!i.key) i.key = i.value
          return i
        })
      }
      return q
    },
    isWorkbookCompleted() {
      return this.questions.length < this.currentIndex + 1
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (
          this.workbookType.THOUGHT_RECORD === this.workbook.name &&
          this.currentIndex === 2
        ) {
          const main = this.form[Object.keys(this.form)[this.currentIndex]]
          const items =
            (this.questions[this.currentIndex] &&
              this.questions[this.currentIndex].values.filter(
                (i) => main && main.includes(i.key)
              )) ||
            []
          this.SET_GROUPS(items.map((i) => i.value))
          this.setQuestions()
        }
      },
    },
  },
  mounted() {
    this.form = this.answerForm
  },
  methods: {
    ...mapMutations('workbook', [
      'UPDATE_INDEX',
      'SET_GROUPS',
      'SET_ANSWER_FORM',
    ]),
    ...mapActions('workbook', ['setQuestions', 'prepareWorkbook']),
    onContinue() {
      if (this.questions.length <= this.currentIndex + 1) {
        this.SET_ANSWER_FORM(this.form)
        const questions = this.questions.map((i) => {
          const m_answer = () => {
            if (workbookFieldType.MULTI_SELECT === i.type) {
              return (
                i.values &&
                i.values
                  .filter((j) => this.form[i.id].includes(j.key))
                  .map((j) => j.value)
              )
            }
            return this.form[i.id]
          }
          const s_answer = () => {
            if (workbookFieldType.SELECT === i.type) {
              const ans = i.values.find(
                (j) => j.key === this.form[i.id] || j.value === this.form[i.id]
              )
              return ans ? ans.value : ''
            }
            return this.form[i.id]
          }
          return {
            ...i,
            answer: {
              multiple_answer: m_answer(),
              rate_answer:
                workbookFieldType.RANGE === i.type ? this.form[i.id] : null,
              single_answer: s_answer(),
            },
          }
        })
        const workbook = {
          ...this.workbook,
          question: questions,
        }
        this.prepareWorkbook(workbook)
      }
      this.UPDATE_INDEX(this.currentIndex + 1)
    },
    onAddTextarea() {
      this.form[this.current.id].push('')
    },
    onChangeTextarea(e, id, index) {
      if (!this.form[id]) {
        this.form[id] = []
      }
      this.form[id][index] = e.target.value
    },
    onDone() {
      this.$router.push({
        name: 'WorkbookResult',
        params: { id: this.$route.params.id, forReview: true },
      })
    },
    onCheckboxChange(e, id) {
      if (!this.form[id]) {
        this.form[id] = []
      }
      if (e.target.checked) {
        this.form[id].push(e.target.value)
      } else {
        this.form[id] = this.form[id].filter((i) => i !== e.target.value)
      }
      if (
        [this.workbookType.GENERAL_ISSUES, this.workbookType.GOALS].includes(
          this.workbook.name
        )
      ) {
        const first = this.form[Object.keys(this.form)[0]]
        const items =
          (this.questions[0] &&
            this.questions[0].values.filter(
              (i) => first && first.includes(i.key)
            )) ||
          []
        this.SET_GROUPS(items.map((i) => i.value))
        this.setQuestions()
      } else if (
        this.workbookType.MONITORING_MOODS === this.workbook.name &&
        this.currentIndex === 1
      ) {
        const main = this.form[Object.keys(this.form)[1]]
        const items =
          (this.questions[1] &&
            this.questions[1].values.filter(
              (i) => main && main.includes(i.key)
            )) ||
          []
        this.SET_GROUPS(items.map((i) => i.value))
        this.setQuestions()
      } else if (
        this.workbook.THOUGHT_RECORD === this.workbook.name &&
        this.currentIndex === 3
      ) {
        const main = this.form[Object.keys(this.form)[this.currentIndex]]
        const items =
          (this.questions[this.currentIndex] &&
            this.questions[this.currentIndex].values.filter(
              (i) => main && main.includes(i.key)
            )) ||
          []
        this.SET_GROUPS(items.map((i) => i.value))
        this.setQuestions()
      }
    },
  },
}
</script>

<style>
.flex-center {
  display: flex;
  justify-content: center;
}
.add-btn {
  margin-top: 16px;
}
</style>
