<template>
  <div class="workbook-header">
    <div class="workbook-header__body">
      <button @click="onBack" class="workbook-header__back-btn">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Previous question"
        />
      </button>
      <a-progress
        class="workbook-header__progress"
        :percent="currentPercent"
        :showInfo="false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'WorkbookHeader',
  computed: {
    ...mapState('workbook', ['currentIndex']),
    ...mapGetters('workbook', ['currentPercent']),
  },
  methods: {
    ...mapMutations('workbook', ['UPDATE_INDEX']),
    onBack() {
      if (this.currentIndex === 0) {
        this.$router.back()
      } else this.UPDATE_INDEX(this.currentIndex - 1)
    },
  },
}
</script>

<style lang="scss">
.workbook-header {
  padding: 36px 36px 0;
  &__body {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__back-btn {
    position: absolute;
    left: 0;
    background-color: inherit;
    border: none;
    cursor: pointer;
    top: -2px;

    @media (max-width: 450px) {
      margin-right: 10px;
      position: relative;
    }
  }

  &__progress {
    max-width: 327px;

    .ant-progress-inner {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .ant-progress-bg {
      background-color: rgba(#fff, 0.5);
    }
  }
}
</style>
