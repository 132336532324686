<template>
  <workbooks />
</template>

<script>
import Workbooks from '@/components/workbook/Workbooks.vue'
export default {
  name: 'Workbook',
  components: { Workbooks },
}
</script>

<style></style>
